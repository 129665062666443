<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:actions>
        <a-btn-refresh @click="$refs.table.updateData()" />
        <a-btn-add :icon="'far fa-plus-square'" :title="'Создать документ'" v-if="getAccess('documents.actWorkClient.create')" @click="createNew()" />
      </template>
      <portal to="v-main">
        <s-document-head v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="m.title" :id="idEdit" />
      </portal>
      <a-table-f-api
        ref="table"
        :api="url"
        :model="getModelList(m)"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
      >
      </a-table-f-api>
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess, genModel, doubleClickDetect, keyDetect, autoHeightBlock } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel, doubleClickDetect, keyDetect, autoHeightBlock],
  components: {
    ///editDialog: () => import("./dialogs/actWorkClientDialog"),
    ViewItem: () => import("./views/actWorkClientView"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      idItemShow: 0,
      title: "",
      m: this.$store.getters["config/get"].models.documentActWorkClient,

      url: "/accounting/doc/act_work_client",
      defaults: {
        sort: { key: "date_doc", order: "DESC" },
        filters: [10, 20].includes(this.$root.profile.role) ? {} : this.getAccess("documents.actWorkClient.viewAll") ? {} : { createdby_id: this.$root.profile.id },
        paramName: "documentActWorkClient",
      },

      infoData: null,
      infoModel: null,
      array_object: [],
      loaded: false,
      filter: this.getAccess("documents.actWorkClient.viewAll") ? {} : { createdby_id: this.$root.profile.id },
    };
  },
  created() {
    this.$root.title = this.$route.meta.title;
    this.setFilters();
  },
  computed: {
    defaults2: {
      get() {
        return {
          sort: { key: "date_doc", order: "DESC" },
          filters: this.filter,
          paramName: "documentActWorkClient",
        };
      },
    },
    async filter1() {
      let res;
      if (![10, 20].includes(this.$root.profile.role)) {
        res = this.getAccess("documents.actWorkClient.viewAll") ? {} : { createdby_id: this.$root.profile.id };
      } else {
        const arr = await this.getObjectsArray;
        res = { object_id: arr };
      }

      return res;
    },
    configHead() {
      return this.m.config.default || {};
    },
    permit() {
      return this.getAccess("menu.documentActWorkClient");
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    async setFilters() {
      let res;
      if (![10, 20].includes(this.$root.profile.role)) {
        return;
        res = this.getAccess("documents.actWorkClient.viewAll") ? {} : { createdby_id: this.$root.profile.id };
      } else {
        let array_object = [-1];
        let resp = await this.$axios.get("/mechti/objects", {});
        //console.log("work", resp);
        if (resp.data.data.length > 0) {
          resp.data.data.forEach((el) => {
            array_object.push(el.id);
          });
        } else {
        }
        // const arr = await this.getObjectsArray;
        console.log("rooooole", this.$root.profile.role, array_object);
        res = {}; //{ object_id: array_object, status: [1, 2, 3] };
      }

      this.filter = res;
      this.loaded = true;
    },
    async getObjectsArray() {
      let array_object = [-1];
      let resp = await this.$axios.get("/mechti/objects", {});
      //console.log("work", resp);
      if (resp.data.data.length > 0) {
        resp.data.data.forEach((el) => {
          array_object.push(el.id);
        });
      } else {
      }
      return array_object;
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },

    onSingleClick(d) {},
    onDoubleClick(d) {
      this.idItemShow = d.row.id;
      return;
      this.onClickRow(d);
    },

    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
